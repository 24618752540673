<template>
  <div id="indexes">
    <searchV1 class="search" v-model="search" @search="searchGoods"></searchV1>
    <div class="label" >历史搜索</div>
    <div class="content">
      <div
        v-for="(item, index) in searchHistory"
        :key="index"
        @click="searchGoods2(item)"
      >
        <label class="item">{{ item }}</label>
        <div class="gap"></div>
      </div>
    </div>
    <label class="label" v-if="hot.length > 0">热门搜索</label>
    <div class="content">
      <div
        v-for="(item, index) in hot"
        :key="index"
        @click="searchGoods2(item)"
      >
        <label class="item">{{ item }}</label>
        <div class="gap"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import searchV1 from "@T/community/search/searchV1.vue";
export default {
  components: {
    searchV1,
  },
  data() {
    return {
      search: "",
      hot: [],
    };
  },
  computed: {
    ...mapState("community_search", {
      searchHistory: (state) => state.history,
    }),
  },
  created() {
    document.title = "搜索";
    this.getHotSearch();
  },
  methods: {
    ...mapActions("community_search", ["addHistory"]),
    searchGoods() {
      this.addHistory(this.search);
      let keyword = this.$utils.string.trim(String(this.search));
      if (keyword != "") {
        this.searchGoods2(keyword);
      }
    },
    searchGoods2(item) {
      this.$router.push({ path: "search-list", query: { keyword: item } });
    },
    async getHotSearch() {
      try {
        let query = await this.$api.community.search.getHotSearch();
        this.hot = query.data.map((val) => val.name);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#indexes {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  min-height: 100%;
  padding: 15px 15px;
  background: #fff;
}
.search {
  width: 345px;
  height: 32px;
}
.label {
  margin-top: 28px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.content {
  display: flex;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}
.item {
  margin-top: 12px;
  background: #f6f6f6;
  border-radius: 3px;
  padding: 10px 16px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.gap {
  flex-shrink: 0;
  width: 12px;
  height: 20px;
}
</style>
